import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { isChicoreeApp } from '@utils/environmentCheck';
import { getRouteUrl } from '@utils/urls';

import { HOME } from '@common/routes';
import Layout from '@common/containers/Layout';
import RouteLayout from '@common/containers/RouteLayout';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';

import { Title } from './NotFound.styled';

export function NotFound() {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const isAppEnvironemnt = isChicoreeApp();

  return (
    <Layout
      title={t(
        'notFound.metaTitle',
        'Leider wurde die gewünschte Seite nicht gefunden.',
      )}
      canonicalPageUrl={null}
      noFooter={isAppEnvironemnt}
      noNavbar={isAppEnvironemnt}
    >
      <RouteLayout columns={8} withoutStickyBar>
        <div>
          <Title>
            {t(
              'notFound.metaTitle',
              'Leider wurde die gewünschte Seite nicht gefunden.',
            )}
          </Title>
          <Link
            legacyBehavior
            href={HOME}
            passHref
            as={getRouteUrl(locale, HOME)}
          >
            <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
              {t('notFound.homeButtonText', 'Zur Startseite')}
            </ButtonLink>
          </Link>
        </div>
      </RouteLayout>
    </Layout>
  );
}

export default NotFound;
