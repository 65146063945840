import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import NotFound from '@routes/NotFound';

const Error404Page = () => <NotFound />;

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
});

export default Error404Page;
