import styled from 'styled-components';

import Typography from '@common/components/Typography';

export const Title = styled(Typography).attrs({
  customAs: 'h1',
  variant: 'h1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
`;
